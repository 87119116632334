import cn from 'classnames'
import GreenCheckIcon from 'components/dashboard/clients/coach-home/coach-marketplace-steps-carousel/coach-marketplace-step-carousel-card/green-check-icon'
import s from './styles.module.css'
import RedXIcon from './red-x-icon'
import kickoffIcon from 'images/kickoff-icon.svg'
import KickoffLogoWords from './kickoff-logo-words'
import { useIsPaidTrial } from 'hooks/use-is-paid-trial'

const ValueProp = ({ fields }) => {
  const { header } = fields
  const { split: paidTrialSplit } = useIsPaidTrial()
  return (
    <section className={s.container}>
      <div className={s.content}>
        <img
          alt="Trainer Headshot"
          src="https://res.cloudinary.com/kickoff-personal-training/image/upload/v1725396299/static/User_Photo_1_zt6itq.jpg"
          className={s.floatingImg}
        />
        <div className={s.header}>{header}</div>
        <div className={s.grid}>
          <div>
            <div className={s.gridHeaderPlaceholder} />
            <div className={s.rowLabel}>Strong support & accountability</div>
            <div className={s.rowLabel}>Integration exercise & nutrition</div>

            <div className={s.rowLabel}>On demand, anytime, anywhere</div>

            <div className={s.rowLabel}>No contracts or cancel fees</div>

            <div className={s.rowLabel}>FSA/HSA eligible</div>
          </div>
          <div className={s.column}>
            <div className={s.gridHeader}>In-Person Trainer or Dietitian</div>
            <div className={s.gridItem}>
              <GreenCheckIcon />
            </div>
            <div className={s.gridItem}>
              <RedXIcon />
            </div>
            <div className={s.gridItem}>
              <RedXIcon />
            </div>
            <div className={s.gridItem}>
              <RedXIcon />
            </div>
            <div className={cn(s.gridItem, s.last)}>
              <RedXIcon />
            </div>
          </div>
          <div className={s.column}>
            <div className={s.gridHeader}>
              <KickoffLogoWords className={s.logoWords} />
              <img
                className={s.kickoffIcon}
                src={kickoffIcon}
                alt="kickoff icon"
              />
            </div>
            <div className={s.gridItem}>
              <GreenCheckIcon />
            </div>
            <div className={s.gridItem}>
              <GreenCheckIcon />
            </div>
            <div className={s.gridItem}>
              <GreenCheckIcon />
            </div>
            <div className={s.gridItem}>
              <GreenCheckIcon />
            </div>
            <div className={cn(s.gridItem, s.last)}>
              <GreenCheckIcon />
            </div>
          </div>
          <div />
          <div className={cn(s.priceCard, s.competitorCard)}>
            <span className={cn(s.priceText, s.competitorPriceText)}>
              $100+
            </span>
            <span className={cn(s.durationText, s.competitorDurationText)}>
              per session
            </span>
            <span className={s.emojis}>😡 😤</span>
          </div>
          <div className={cn(s.priceCard, s.kickoffCard)}>
            <span className={cn(s.priceText, s.kickoffPriceText)}>
              ${paidTrialSplit === 'B' ? 5 : 3}
            </span>
            <span className={cn(s.durationText, s.kickoffDurationText)}>
              per day
            </span>
            <span className={s.emojis}>😁 😇</span>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ValueProp
