import { useQuery, useMutation } from '@apollo/client'
import * as R from 'ramda'
import { useMemo, useState, useEffect } from 'react'
import { setCookie } from 'nookies'
import Router from 'next/router'

import Affiliates from 'components/homepage/affiliates'
import CoachProfileHero from 'components/coach-profile/coach-profile-hero'
import CoachProfileNav from 'components/coach-profile-nav'
import CoachProfileSignUp from 'components/coach-profile-sign-up'
import ComparisonTable from 'components/homepage/comparison-table'
import CTA from 'components/homepage/cta'
import TestimonialsB from 'components/homepage/testimonials-b'
import HowItWorksB from 'components/homepage/how-it-works-b'
import GetStartedB from 'components/homepage/get-started-b'
import HeroB from 'components/homepage/hero-b'
import HeroC from 'components/homepage/hero-c'
import HeroD from 'components/homepage/hero-d'
import About from 'components/homepage/about'
import Accountability from 'components/homepage/accountability'
import Banner from 'components/homepage/banner'
import Footer from 'components/homepage/footer'
import GetStarted from 'components/homepage/get-started'
import Hero from 'components/homepage/hero'
import HowItWorks from 'components/homepage/how-it-works'
import Nav from 'components/homepage/nav'
import Pricing from 'components/homepage/pricing'
import Reviews from 'components/homepage/reviews'
import SignUp from 'components/homepage/sign-up'
import Testimonials from 'components/homepage/testimonials'
import Trainers from 'components/homepage/trainers'
import ValueProp from 'components/homepage/value-prop'
import WhatIsKickoff from 'components/homepage/what-is-kickoff'
import FeatureCarousel from 'components/homepage/feature-carousel'
import Faq from 'components/faq'
import NavExperiment from '../homepage/experiment/nav'
import NavExperimentDesignSystem from '../homepage/experiment/nav-design-system'
import HeroExperiment from '../homepage/experiment/hero'
import AffiliatesExperiment from '../homepage/experiment/affiliates'
import ImpactStatementExperiment, {
  ImpactStatement,
} from '../homepage/experiment/impact-statement'
import PricingExperiment from '../homepage/experiment/pricing'
import FeatureExperiment, { Feature } from '../homepage/experiment/feature'
import ReviewsExperiment from '../homepage/experiment/reviews'
import GetStartedExperiment from '../homepage/experiment/get-started'
import TrainersExperiment from '../homepage/experiment/trainers'
import FooterExperiment from '../homepage/experiment/footer'
import StickyCTAExperiment from '../homepage/experiment/sticky-cta'

import ReviewsExperimentDesignSystem from '../homepage/experiment-design-system/reviews'
import TestimonialsExperimentDesignSystem from '../homepage/experiment-design-system/testimonials'
import HowItWorksCExperimentDesignSystem from '../homepage/experiment-design-system/how-it-works-c'
import HowItWorksDExperimentDesignSystem from '../homepage/experiment-design-system/how-it-works-d'
import PricingExperimentDesignSystem from '../homepage/experiment-design-system/pricing'

import Faqs from 'components/shared/faqs'
import { Breadcrumb } from 'components/city-page/breadcrumbs'
import { CityHero } from 'components/city-page/hero'
import { IndexedReasonList } from 'components/city-page/indexed-reason-list'
import { NearCoachesList } from 'components/city-page/near-coaches-list'
import { CityComparisonTable } from 'components/city-page/city-comparison-table'
import { CityFaqs } from 'components/city-page/city-faqs'

import CmsPageContext from 'contexts/cms-page-context'
import { buildSections } from 'utilities/cms-survey'

import { getAdjustedUtcOffset } from 'utilities/dates'
import { SESSION_TOKEN } from 'constants/cookies'
import { DAY } from 'constants/seconds'
import urls from 'utilities/urls'
import { pageCategory } from 'utilities/gtm'
import BlogNav from './blog-nav'
import BlogHero from './blog-hero'
import BlogContent from './blog-content'
import { submitClientSignUpSurvey as submitClientSignUpSurveyMutation } from './mutations.graphql'
import { getSourceCoupon } from './queries.graphql'
import ChatButtonExperiment from '../homepage/experiment/common/chat-button'
import CoachProfileHeroExperiment from '../coach-profile/coach-profile-hero'
import CoachProfileSignUpExperiment from '../coach-profile-sign-up/experiment'
import { LandingPageAnalytics } from '../homepage/analytics'
import { OtherCitiesGrid } from '../city-page/other-cities-grid'
import { OtherStateTrainersGrid } from '../city-page/other-state-trainers-grid'
import { OtherStateCitiesGrid } from '../city-page/other-state-cities-grid'
import { AllStatesGrid } from '../city-page/all-states-grid'
import { IntroCopy } from '../city-page/intro-copy'
import { StateCopy } from '../city-page/state-copy'
import { useClientTrialDays } from 'hooks/use-client-trial-days'
import TestimonialsV2 from '../shared/testimonials-v2'
import DefaultMarkdown from 'components/default-markdown'
import BlankNav from 'components/homepage/blank-nav'
import { getFirstStringFromOptionalArray } from 'utilities/strings'
import CoachAgreementCheck from 'components/coach-application/coach-agreement-check'
import HomepageUpgradeHeroExperiment from 'components/homepage/experiment/homepage-upgrade/hero'
import HomepageUpgradeAffiliatesExperiment from 'components/homepage/experiment/homepage-upgrade/affiliates'
import HomepageUpgradeValueProp from 'components/homepage/experiment/homepage-upgrade/value-prop'
import HomepageupgradeImpactStatementExperiment from 'components/homepage/experiment/homepage-upgrade/impact-statement'
import HomepageUpgradeHowItWorksExperiment from 'components/homepage/experiment/homepage-upgrade/how-it-works'
import HomepageUpgradeBrandedHeaderExperiment from 'components/homepage/experiment/homepage-upgrade/branded-header'
import HomepageUpgradeFeatureExperiment from 'components/homepage/experiment/homepage-upgrade/feature'
// import HomepageUpgradePricingExperiment from 'components/homepage/experiment/homepage-upgrade/pricing'
import HomepageUpgradeReviewsExperiment from 'components/homepage/experiment/homepage-upgrade/reviews'
import HomepageUpgradeTrainersExperiment from 'components/homepage/experiment/homepage-upgrade/trainers'
import HomepageUpgradeFaqsExperiment from 'components/homepage/experiment/homepage-upgrade/faqs'
import HomepageUpgradeGetStartedExperiment from 'components/homepage/experiment/homepage-upgrade/get-started'
import HomepageUpgradeStickyCTAExperiment from 'components/homepage/experiment/homepage-upgrade/sticky-cta'
import { useExperimentSplit } from 'utilities/experiment/context'
import dynamic from 'next/dynamic'

const HomepageUpgradePricingExperiment = dynamic(
  () => import('components/homepage/experiment/homepage-upgrade/pricing'),
  { ssr: false }
)

const IS_PROD = process.env.APP_ENV === 'production'

const HOMEPAGE_UPGRADE_SECTION_COMPONENTS = {
  HeroExperiment: HomepageUpgradeHeroExperiment,
  AffiliatesExperiment: HomepageUpgradeAffiliatesExperiment,
  ValuePropExperiment: HomepageUpgradeValueProp,
  ImpactStatementExperiment: HomepageupgradeImpactStatementExperiment,
  HowItWorksExperiment: HomepageUpgradeHowItWorksExperiment,
  BrandedHeaderExperiment: HomepageUpgradeBrandedHeaderExperiment,
  FeatureExperiment: HomepageUpgradeFeatureExperiment,
  PricingExperiment: HomepageUpgradePricingExperiment,
  ReviewsExperiment: HomepageUpgradeReviewsExperiment,
  TrainersExperiment: HomepageUpgradeTrainersExperiment,
  CityFaqs: HomepageUpgradeFaqsExperiment,
  GetStartedExperiment: HomepageUpgradeGetStartedExperiment,
  StickyCTAExperiment: HomepageUpgradeStickyCTAExperiment,
}

const SECTION_COMPONENTS = {
  About,
  Accountability,
  Affiliates,
  Banner,
  ComparisonTable,
  CTA,
  TestimonialsB,
  HowItWorksB,
  GetStartedB,
  HeroB,
  HeroC,
  HeroD,
  Footer,
  GetStarted,
  Hero,
  HowItWorks,
  Nav,
  Pricing,
  Reviews,
  SignUp,
  Testimonials,
  TestimonialsV2,
  Trainers,
  CoachProfileHero,
  CoachProfileSignUp,
  CoachProfileNav,
  BlogNav,
  BlogHero,
  BlogContent,
  ValueProp,
  WhatIsKickoff,
  FeatureCarousel,
  Faq,
  ImpactStatement,
  Feature,
  Faqs,
  DefaultMarkdown,
  CoachAgreementCheck,
  BlankNav,
}

const EXPERIMENT_COMPONENTS = {
  NavExperiment,
  NavExperimentDesignSystem,
  HeroExperiment,
  AffiliatesExperiment,
  ImpactStatementExperiment,
  PricingExperiment,
  FeatureExperiment,
  ReviewsExperiment,
  GetStartedExperiment,
  TrainersExperiment,
  FooterExperiment,
  StickyCTAExperiment,
  ChatButtonExperiment,
  CoachProfileHeroExperiment,
  CoachProfileSignUpExperiment,
  TestimonialsExperimentDesignSystem,
  ReviewsExperimentDesignSystem,
  HowItWorksCExperimentDesignSystem,
  HowItWorksDExperimentDesignSystem,
  PricingExperimentDesignSystem,
}

const LOCAL_PAGE_COMPONENTS = {
  CityFaqs,
  CityHero,
  Breadcrumb,
  IndexedReasonList,
  NearCoachesList,
  CityComparisonTable,
  OtherCitiesGrid,
  OtherStateTrainersGrid,
  OtherStateCitiesGrid,
  AllStatesGrid,
  IntroCopy,
  StateCopy,
}

const CmsPage = ({
  survey,
  surveyId,
  query,
  fields,
  coach,
  source,
  slug,
  blogProps = undefined,
  isCoachPage = false,
  isBlog = false,
  isMobile = false,
  locationDetails,
  localPageType,
  landingPageOptions,
  contentSlug,
  ...rest
}) => {
  const [selectedPriceId, selectPriceId] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [isSafari, setIsSafari] = useState(false)
  const [submitClientSignUpSurvey] = useMutation(
    submitClientSignUpSurveyMutation
  )
  const homepageUpgradeExperimentSplit = useExperimentSplit('homepageUpgrade')
  const isInHomepageUpgradeExperiment =
    homepageUpgradeExperimentSplit === 'B' && contentSlug === '_reskinUpgrade'

  const trialDays = useClientTrialDays()
  const cmsCouponCode = IS_PROD ? fields.coupon : fields.stagingCoupon
  const sourceCouponPayload = useQuery(getSourceCoupon, {
    variables: {
      source,
      couponCode: cmsCouponCode || undefined,
      referredByCoachId: isCoachPage ? coach?.id : undefined,
      trialDays,
    },
    skip: !source && !isCoachPage && !cmsCouponCode,
  })
  const coupon = sourceCouponPayload.data?.sourceCoupon
  // only use coupon code if it returns a valid coupon
  const couponCode = cmsCouponCode || null

  const sections = useMemo(() => {
    // remove discount banner for coach page without discount or free trial
    if (isCoachPage && !coupon && !trialDays) {
      return R.reject(
        R.pathEq(['fields', 'component'], 'Banner'),
        fields.sections
      )
    }

    return fields.sections
  }, [coupon, fields.sections, isCoachPage, trialDays])

  const signUpSurveyQuestions = useMemo(() => {
    if (!survey) return []

    const surveySections = buildSections({ survey, isAdmin: false })

    return R.pipe(
      R.pathOr([], [0, 'groups']),
      R.map(R.prop('questions')),
      R.flatten
    )(surveySections)
  }, [survey])

  useEffect(() => {
    setIsSafari(
      /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent)
    )
  }, [])

  const onAnchorLinkClick = ev => {
    ev.preventDefault()

    const href = ev.currentTarget.getAttribute('href')
    const [, anchor] = href.split('#')
    if (!anchor) {
      const { id, ...queryParams } = query
      Router.push({ pathname: href, query: queryParams })
      return
    }
    pageCategory(anchor)
    document.querySelector(`#${anchor}`)?.scrollIntoView({ behavior: 'smooth' })
    // window.history.pushState(null, null, `#${anchor}`)
  }

  const getRef = () => {
    if (isBlog) return 'blog'
    if (localPageType) return source

    return getFirstStringFromOptionalArray(query.ref || query.refwh)
  }

  const signUp = async ({ responses = [], surveyParams = {} } = {}) => {
    if (submitting) return

    setSubmitting(true)

    // if no survey responses to save, send directly to survey without creating client
    if (responses.length === 0 && !isCoachPage) {
      const { href, as } = urls.clientSignUp({
        ...query,
        ...surveyParams,
        ref: getRef(),
        gclid: query.gclid,
        msclkid: query.msclkid,
        fbclid: query.fbclid,
        couponCode: couponCode || undefined,
      })

      return Router.push(href, as)
    }

    const utcOffset = getAdjustedUtcOffset()
    const { href, as } = urls.clientSignUp({
      ...surveyParams,
    })
    if (!isCoachPage) {
      Router.prefetch(href)
    }

    try {
      const {
        data: {
          submitClientSignUpSurvey: { sessionToken, paymentToken },
        },
      } = await submitClientSignUpSurvey({
        variables: {
          ref: getRef(),
          gclid: query.gclid,
          msclkid: query.msclkid,
          fbclid: query.fbclid,
          utcOffset,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          responses,
          referredByCoachId: R.propOr(null, 'id', coach),
          couponCode,
        },
      })

      LandingPageAnalytics.onSignUp()
      await setCookie({}, SESSION_TOKEN, sessionToken, {
        path: '/',
        maxAge: 30 * DAY,
      })

      if (isCoachPage) {
        Router.push(
          urls.subscribe({
            token: paymentToken,
            params: {
              referredByCoachId: R.propOr(null, 'id', coach),
              priceId: surveyParams.priceId,
            },
          })
        )
      } else {
        Router.push(href, as)
      }
    } catch (serverError) {
      setSubmitting(false)
      throw serverError
    }
  }

  const hasBanner = useMemo(() => {
    const hasBannerSection = R.find(
      R.pathEq(['fields', 'component'], 'Banner')
    )(sections)

    const ref = getFirstStringFromOptionalArray(query?.ref)
    const [platform, freetrial] = ref?.split('_') || []
    const refValue =
      platform === 'promo' && !freetrial
        ? platform
        : platform === 'gp' && freetrial
        ? platform
        : freetrial === 'freetrial'
        ? 'freetrial'
        : ''
    return hasBannerSection && (refValue || isCoachPage || coupon)
  }, [sections, query?.ref, isCoachPage, coupon])

  const contextValue = {
    signUpSurveyQuestions,
    surveyId,
    query,
    onAnchorLinkClick,
    coach,
    isCoachPage,
    isBlog,
    signUp,
    coupon,
    getRef,
    isSafari,
    couponCode,
    selectedPriceId,
    selectPriceId,
    hasBanner,
    isMobile,
    locationDetails,
    localPageType,
  }

  return (
    <CmsPageContext.Provider value={contextValue}>
      <div {...rest}>
        <>
          {R.addIndex(R.map)(({ fields }, idx) => {
            let SectionComponent =
              SECTION_COMPONENTS[fields.component] ||
              EXPERIMENT_COMPONENTS[fields.component] ||
              LOCAL_PAGE_COMPONENTS[fields.component]
            const props = {}
            if (
              isInHomepageUpgradeExperiment &&
              HOMEPAGE_UPGRADE_SECTION_COMPONENTS[fields.component]
            ) {
              SectionComponent =
                HOMEPAGE_UPGRADE_SECTION_COMPONENTS[fields.component]
            }
            if (
              SectionComponent === Nav &&
              R.hasPath(['options', 'linksToExclude'], fields)
            ) {
              props.linksToExclude = R.path(
                ['options', 'linksToExclude'],
                fields
              )
            }

            return (
              <SectionComponent
                landingPageOptions={landingPageOptions}
                {...props}
                key={`${fields.component}-${idx}`}
                fields={fields}
                blogProps={blogProps}
              />
            )
          }, sections)}
        </>
      </div>
    </CmsPageContext.Provider>
  )
}

export default CmsPage
